/* Add appropriate styles to make it look nice */
.row {
  margin-bottom: 2px;
}

.form-item-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Media query to change the layout when screen width is less than 1350px */
@media (max-width: 1320px) {
  .form-item-wrapper {
    flex-direction: column;
    display: flex;
    gap: 2px;
  }
}

.form-item-wrapper button {
  flex: 1; /* This will make the buttons equally wide */
  width: 100%; /* Optionally set width to 100% to fill the available space */
}
