/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


@import url('https://cdn.syncfusion.com/ej2/material.css');

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}

/* .ant-btn-primary {
  background-color: #0d6ae4;
  border-color: #0d6ae4;
  color: #fff;
} */
