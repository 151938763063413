/* CustomRangePicker.css */

/* Hide the select element */
.custom-range-picker .ant-calendar-picker-icon,
.custom-range-picker .ant-calendar-range-picker-input {
  display: none !important;
}

/* Style the calendars as needed */
.custom-range-picker .ant-calendar {
  /* Your custom styles for the calendars */
}

.custom-range-picker .ant-calendar-range-part {
  /* Your custom styles for each calendar part */
}

/* .ant-btn-primary {
  background-color: #0d6ae4;
  border-color: #0d6ae4;
  color: #fff;
} */
